.contact-form {
  font-size: clamp(15px, 3vw, 20px);
  display: flex;
  flex-direction: column;
  /* padding: 60px 100px 0px 100px; */
  padding: 6vh 6vw;
  /* min-width: 615px; */
  /* max-width: 1000px; */
}

.contact-form__title {
  align-self: center;
  text-align: center;
  /* font-weight: bold; */
}

.contact-form__input-label {
  margin-top: 20px;
  cursor: text;
}

.contact-form Button {
  margin: 40px auto;
}
